import React from "react";
import './Header.css';
import Socials from "../Socials";

const Header = () => {
    return (
        <div className="Header">
            <Socials />
        </div>
    )
}

export default Header;