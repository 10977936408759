import './App.css';
import Clicker from './components/Clicker';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
        <Header />
        <Clicker />
        <Footer />
    </div>
  );
}

export default App;
