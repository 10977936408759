import React from "react";
import './Footer.css';

const Footer = () => {
    return (
        <div className="Footer">
            <a className="FooterButton" href="https://t.me/dotcoin_bot" target="_blank" rel="noreferrer">Play now</a>
        </div>
    )
}

export default Footer;