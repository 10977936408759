import React from "react";
import { ReactComponent as XIcon } from './x.svg';
import { ReactComponent as TGIcon } from './tg.svg';
import './Socials.css';

const Socials = () => {
    return (
        <div className="Socials">
            <a className="SocialsButton" href="https://twitter.com/thedotcoin" target="_blank" rel="noreferrer">
                <XIcon width="32px" height="32px" fill="#ffffff"/>
            </a>
            <a className="SocialsButton" href="https://t.me/dotcoin_ton" target="_blank" rel="noreferrer">
                <TGIcon width="32px" height="32px" fill="#ffffff"/>
            </a>
        </div>
    )
}

export default Socials;