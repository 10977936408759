import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion"

import './Clicker.css';

const Clicker = () => {
    const clickerContainer = useRef(null);
    const dotRef = useRef(null);
    const intervalIdRef = useRef();
    const [clicks, setClicks] = useState([])
    const sizeRef = useRef(50);
    const [multiplier, setMultiplier] = useState(1);

    useEffect(() => {

        intervalIdRef.current = setInterval(() => {
            setDotSize(Math.max(sizeRef.current - 1, 50));
        }, 150);

        return () => {
            clearInterval(intervalIdRef.current);
        }; 
        
    }, [])

    const setDotSize = (size) => {
        sizeRef.current = size;
        dotRef.current.style.width = size + 'px';
        dotRef.current.style.height = size + 'px';
        switch (true) {
            case size < 100:
                setMultiplier(1)
                break;
            case size >= 100 && size < 150:
                setMultiplier(2)
                break;
            case size >= 150 && size < 180:
                setMultiplier(3)
                break;
            case size >= 180 && size < 200:
                setMultiplier(4)
                break;
            default:
                setMultiplier(1);
        }
    }

    const handleClick = async() => {

        // При каждом клике нужно рассчитать размер точки и добавить кол-во монет в переменную coins
        setDotSize(Math.min(sizeRef.current + 3, 200));

        if (sizeRef.current === 200) {
            setDotSize(50);
        }
    }

    const handleTouchStart = (event) => {
        handleClick();
        if (clickerContainer.current) {
            const shiftX = Math.floor(Math.random() * 21) - 10;
            const clickPoint = event;
            const clickData = {
                id: `${Date.now()}`,
                value: 1, //click value
                x: clickPoint.clientX + shiftX,
                y: clickPoint.clientY
            };
            setClicks(clicksArr => [...clicksArr, clickData])
            setTimeout(() => {
                setClicks(clicksArr => clicksArr.filter(item => item.id !== clickData.id))
            }, 1000)
        }
    }

    return (
        <div className="ClickerPanel">
            <div className="Clicker">
                <div ref={clickerContainer} className="ClickerCoin" onClick={(e) => handleTouchStart(e)} >
                    <div className="ClickerCoinRing ClickerCoinRing100"></div>
                    <div className="ClickerCoinRing ClickerCoinRing150"></div>
                    <div className="ClickerCoinRing ClickerCoinRing180"></div>
                    <div className="ClickerCoinRing ClickerCoinRing200"></div>
                    <div className="ClickerCoinDot" ref={dotRef} style={{
                        width: sizeRef.current + 'px',
                        height: sizeRef.current + 'px',
                        fontSize: 9 + multiplier * 3
                    }} data-text={`X${multiplier}`}></div>
                </div>
                <div className="ClickerCoinAmount">
                    <AnimatePresence>
                        {clicks.map(click => (
                            <motion.div className="ClickerCoinAmountDiv" key={click.id}
                                initial={{
                                    opacity: 1,
                                    y: click.y - 50,
                                    x: click.x
                                }}
                                animate={{
                                    opacity: 0,
                                    y: click.y - 200
                                }}
                                exit={{ opacity: 0 }}
                                transition={{
                                    duration: 1
                                }}
                            >
                                <div className="ClickerCoinAmountDivValue">{click.value * multiplier}</div>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    )
}

export default Clicker;